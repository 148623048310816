/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_flame': {
    width: 41,
    height: 42,
    viewBox: '0 0 41 42',
    data: '<g clip-path="url(#clip0_5602_12122)"><path pid="0" d="M14.493 41.086c.396.21.814-.246.572-.623-1.318-2.05-2.565-5.5-.578-9.942 3.314-7.408 5.33-11.245 5.33-11.245s1.073 4.478 3.98 8.459c2.798 3.831 4.33 8.65 1.86 12.665-.23.377.178.821.572.62 3.056-1.564 6.484-4.7 6.87-10.94.143-1.908-.07-4.578-1.14-7.947-1.375-4.27-3.066-6.262-4.044-7.118a.438.438 0 00-.723.359c.285 4.607-1.449 5.777-2.435 3.142-.394-1.053-.624-2.873-.624-5.09 0-3.69-1.07-7.49-3.431-10.576A11.98 11.98 0 0018.545.657a.437.437 0 00-.703.378c.181 2.502.017 9.672-6.273 18.238-5.704 7.946-3.494 14.048-2.71 15.7 1.5 3.167 3.591 5.027 5.634 6.113z" _fill="#FFC300"/></g><defs><clipPath id="clip0_5602_12122"><path pid="1" _fill="#fff" transform="translate(.17 .564)" d="M0 0h40.575v40.575H0z"/></clipPath></defs>'
  }
})
