/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_faq': {
    width: 15,
    height: 13,
    viewBox: '0 0 15 13',
    data: '<path pid="0" d="M7.696 3.045a.762.762 0 100 1.523h3.047a.762.762 0 100-1.523H7.696zM7.696 9.9a.762.762 0 100 1.524h3.047a.761.761 0 100-1.524H7.696z" _fill="#006FB1" fill-opacity=".5"/><path pid="1" d="M7.696.76a.762.762 0 000 1.523h6.094a.762.762 0 100-1.523H7.696zM7.696 7.615a.762.762 0 000 1.524h6.094a.762.762 0 100-1.524H7.696z" _fill="#006FB1" fill-opacity=".8"/><path pid="2" d="M.762 0A.762.762 0 000 .762V4.57a.762.762 0 00.762.762H4.57a.762.762 0 00.762-.762V.762A.762.762 0 004.57 0H.762zM.762 6.852A.762.762 0 000 7.613v3.809a.762.762 0 00.762.762H4.57a.762.762 0 00.762-.762V7.613a.762.762 0 00-.762-.761H.762z" _fill="#006FB1"/>'
  }
})
