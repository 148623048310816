/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_menu': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 18h-9v-2h9v2zm0-5H3v-2h18v2zm0-5H3V6h18v2z" _fill="#0269AD"/>'
  }
})
