/*
App
Created at 09/04/2021 11:44
Author: Khaliq ALI
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import pathResolver from '@/plugins/loader'

Vue.use(VueRouter)

const routes = [
  {
    path: '/about',
    name: 'About',
    component: pathResolver('about', 'index')
  },
  {
    path: '/',
    name: 'Home',
    component: pathResolver('home', 'index')
  },
  {
    path: '/faq',
    name: 'Faq',
    component: pathResolver('faq', 'index')
  },
  {
    path: '/cgu',
    name: 'cgu',
    component: pathResolver('cgu', 'index')
  },
  {
    path: '/politique-confidentialite',
    name: 'politique-confidentialite',
    component: pathResolver('politique-confidentialite', 'index')
  },
  {
    path: '/politique-cookies',
    name: 'politique-cookies',
    component: pathResolver('politique-cookies', 'index')
  },
  {
    path: '/mention',
    name: 'mention-legal',
    component: pathResolver('mention-legal', 'index')
  },
  {
    path: '/details',
    name: 'Details',
    component: pathResolver('details', 'index'),
    redirect: '/auto',
    children: [
      {
        path: '/auto',
        name: 'Auto',
        component: pathResolver('auto'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/moto',
        name: 'Moto',
        component: pathResolver('moto'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/voyage',
        name: 'Voyage',
        component: pathResolver('voyage'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/habitation',
        name: 'Habitation',
        component: pathResolver('habitation'),
        meta: {
          requiresAuth: true
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    // retourner la position désirée
    if (savedPosition) {
      return savedPosition
    } else {
      if (to.hash) {
        return { selector: to.hash }
      } else {
        return { x: 0, y: 0 }
      }
    }
  }
})

export default router
