/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_habitation_insurance': {
    width: 42,
    height: 42,
    viewBox: '0 0 42 42',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M21 42c11.598 0 21-9.402 21-21S32.598 0 21 0 0 9.402 0 21s9.402 21 21 21zm7.994-11.313c.2-.2.313-.472.313-.756v-9.619h3.206L21.476 10.278a1.069 1.069 0 00-1.439 0L9 20.312h3.206v9.62A1.07 1.07 0 0013.276 31h14.962c.284 0 .556-.113.756-.313zM15.947 22.45a4.81 4.81 0 009.62 0h-2.138a2.672 2.672 0 01-5.344 0h-2.138z" _fill="#F44336"/>'
  }
})
