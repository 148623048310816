/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_radio': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<rect pid="0" x="1" y="1" width="10" height="10" rx="3" _stroke="#49BF7C" stroke-width="2"/>'
  }
})
