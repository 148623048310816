/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_estimation': {
    width: 61,
    height: 61,
    viewBox: '0 0 61 61',
    data: '<circle pid="0" cx="30.5" cy="30.5" r="30.5" _fill="#FFA83D" fill-opacity=".1"/><path pid="1" d="M42.167 45.083H18.833a1.459 1.459 0 01-1.458-1.459v-26.25a1.458 1.458 0 011.458-1.458h23.334a1.459 1.459 0 011.458 1.458v26.25a1.458 1.458 0 01-1.458 1.459zm-17.5-18.959v2.917h11.666v-2.917H24.667zm0 5.834v2.916h11.666v-2.916H24.667z" _fill="#FFA83D"/>'
  }
})
