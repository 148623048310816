/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_add-button': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M9 7V0H7v7H0v2h7v7h2V9h7V7H9z" _fill="#fff"/>'
  }
})
