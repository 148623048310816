/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_airplane-mode': {
    width: 35,
    height: 35,
    viewBox: '0 0 35 35',
    data: '<path pid="0" d="M32.836 25.64l1.179-1.178a1.906 1.906 0 000-2.692 1.891 1.891 0 00-1.346-.557c-.381 0-.746.112-1.056.32l-.248-.834 1.276-1.276a1.905 1.905 0 000-2.691 1.904 1.904 0 00-2.506-.161l-1.512-5.075c.604-.582 1.182-1.147 1.727-1.692 1.57-1.571 2.848-3.233 3.596-4.682 1.264-2.447.735-3.756.069-4.422-.667-.667-1.975-1.196-4.423.068-1.448.748-3.11 2.025-4.68 3.596-.546.545-1.112 1.123-1.693 1.727l-5.075-1.512a1.905 1.905 0 00-.16-2.505 1.905 1.905 0 00-2.691 0L14.017 3.35l-.834-.249c.208-.31.32-.674.32-1.056A1.904 1.904 0 0010.254.7L9.075 1.878 3.071.089a.607.607 0 00-.603.153L.922 1.788a.613.613 0 00.07.919l15.153 11.092a569.177 569.177 0 00-9.03 10.465l-4.458-1.715a.607.607 0 00-.647.137L.178 24.52a.607.607 0 000 .858l9.16 9.162a.613.613 0 00.86 0l1.831-1.832a.607.607 0 00.137-.648l-1.714-4.457a568.996 568.996 0 0010.465-9.032l1.203 1.645c.214.292.647.33.91.081a.613.613 0 00.07-.798l-1.272-1.739a271.163 271.163 0 005.809-5.321l5.721 19.208-.79.788-7.862-10.743a.607.607 0 10-.98.717l8.282 11.316c.216.296.66.33.919.07l1.546-1.546a.607.607 0 00.152-.602l-1.789-6.006zM16.152 2.933a.695.695 0 01.973 0 .689.689 0 010 .973l-.285.285-1.5-.447.812-.811zm-5.039-1.374a.689.689 0 01.974.973l-.188.187-1.5-.447.714-.713zm-8.831.586l.789-.788 19.207 5.72a272.978 272.978 0 00-5.322 5.81L2.282 2.145zm.308 21.68l3.697 1.422A553.936 553.936 0 004.23 27.71l-2.763-2.763 1.124-1.123zm2.502 4.75C7.209 26.02 18.398 12.594 25.77 5.222c1.463-1.463 3.06-2.693 4.38-3.375.99-.513 2.317-.978 3.006-.29.689.69.223 2.016-.29 3.007-.681 1.32-1.912 2.917-3.375 4.38-7.371 7.372-20.795 18.562-23.349 20.68l-1.05-1.05zm5.8 3.552L9.768 33.25l-2.763-2.763c.505-.42 1.36-1.13 2.464-2.058l1.423 3.698zm21.29-9.498a.689.689 0 01.974.974l-.714.713-.447-1.5.188-.187zm-1.373-5.038a.69.69 0 01.973 0 .695.695 0 010 .973l-.811.811-.447-1.5.285-.284z" _fill="#606060"/>'
  }
})
