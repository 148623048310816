/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_phone': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path pid="0" d="M5.92 8.027A7.922 7.922 0 008.796 9.89l1.466-1.167a.26.26 0 01.296 0l2.722 1.754a.778.778 0 01.128 1.236l-1.276 1.26a1.555 1.555 0 01-1.411.417 13.647 13.647 0 01-6.67-3.5A13.14 13.14 0 01.447 3.345a1.47 1.47 0 01.428-1.381L2.197.688a.778.778 0 011.214.125L5.223 3.5a.245.245 0 010 .292L4.029 5.227a7.68 7.68 0 001.89 2.8z" _fill="#006FB1"/>'
  }
})
