/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_selection': {
    width: 26,
    height: 26,
    viewBox: '0 0 26 26',
    data: '<circle pid="0" cx="13" cy="13" r="13" _fill="#7735DA"/><path pid="1" d="M7.485 13l4.137 3.743 6.894-7.485" _stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
