/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_close': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M16 1.4L14.6 0 8 6.6 1.4 0 0 1.4 6.6 8 0 14.6 1.4 16 8 9.4l6.6 6.6 1.4-1.4L9.4 8 16 1.4z" _fill="#006FB1"/>'
  }
})
