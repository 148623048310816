/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_signup': {
    width: 61,
    height: 61,
    viewBox: '0 0 61 61',
    data: '<circle pid="0" cx="30.5" cy="30.5" r="30.5" _fill="#3389F8" fill-opacity=".1"/><path pid="1" d="M37.5 29.166h12.833v3.667H37.499v-3.667zm1.833 7.333h11v3.667h-11v-3.667zm-3.667-14.666h14.667v3.666H35.666v-3.666zm-18.333 22h18.333v-1.834c0-5.054-4.112-9.166-9.167-9.166h-3.666c-5.055 0-9.167 4.112-9.167 9.166v1.834h3.667zm7.333-12.834c3.657 0 6.417-2.759 6.417-6.416 0-3.658-2.76-6.417-6.417-6.417s-6.417 2.76-6.417 6.417 2.76 6.416 6.417 6.416z" _fill="#3389F8"/>'
  }
})
