/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_declaration': {
    width: 61,
    height: 61,
    viewBox: '0 0 61 61',
    data: '<circle pid="0" cx="30.5" cy="30.5" r="30.5" _fill="#7735DA" fill-opacity=".1"/><path pid="1" d="M32.23 23.756v-7.11h-4.922a3.1 3.1 0 00-3.099 3.1V37.61a3.1 3.1 0 003.099 3.099h12.031a3.099 3.099 0 003.1-3.099V26.855h-7.11a3.1 3.1 0 01-3.1-3.1z" _fill="#7735DA"/><path pid="2" d="M34.053 23.756v-6.744l8.02 8.02H35.33a1.276 1.276 0 01-1.276-1.276zM20.563 22.99a2.917 2.917 0 011.822-2.705V37.61a4.922 4.922 0 004.922 4.922H38.8a2.918 2.918 0 01-2.705 1.823h-8.787a6.745 6.745 0 01-6.744-6.745V22.99z" _fill="#7735DA"/>'
  }
})
