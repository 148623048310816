/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_thief': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M17.874 19.834H14.1a.943.943 0 110-1.887h3.773a.943.943 0 110 1.887z" _fill="#00BF53"/><path pid="1" d="M22.425 20.817a7.936 7.936 0 001.907-3.823c.049.003.096.01.144.01 1.56 0 2.83-1.269 2.83-2.83 0-1.56-1.27-2.83-2.83-2.83V9.176c0-4.524-3.808-8.206-8.49-8.206-4.68 0-8.489 3.682-8.489 8.206v2.17c-1.56 0-2.83 1.27-2.83 2.83s1.27 2.83 2.83 2.83c.049 0 .096-.008.144-.011a7.934 7.934 0 001.908 3.823C4.71 21.215.895 25.272.895 30.21c0 .522.421.943.943.943H30.136a.943.943 0 00.943-.943c0-4.938-3.817-8.995-8.654-9.393zM9.576 17.004h12.822c-.693 2.703-3.239 4.717-6.273 4.717h-.276c-3.035 0-5.58-2.014-6.273-4.717zm13.014-1.886H9.384v-3.773H22.59v3.773zm2.83-.943c0 .518-.447.928-.944.936v-1.88c.52 0 .944.423.944.944zm-9.433-11.32c3.64 0 6.603 2.836 6.603 6.32v.283H9.384v-.283c0-3.484 2.962-6.32 6.603-6.32zm-9.433 11.32c0-.52.423-.944.943-.944v1.88c-.49-.01-.943-.419-.943-.936zm3.773 8.489h.944v6.603H2.84c.465-3.717 3.646-6.603 7.487-6.603zm2.83 6.603v-6.098a8.56 8.56 0 002.692.438h.276a8.56 8.56 0 002.692-.438v6.098h-5.66zm7.546 0v-6.603h.944c3.84 0 7.022 2.886 7.487 6.603h-8.43z" _fill="#00BF53"/><path pid="2" d="M18.817 14.174h.943a.943.943 0 100-1.887h-.943a.943.943 0 100 1.887zM12.214 14.174h.943a.943.943 0 100-1.887h-.943a.943.943 0 100 1.887z" _fill="#00BF53"/>'
  }
})
