/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_payment': {
    width: 61,
    height: 61,
    viewBox: '0 0 61 61',
    data: '<circle pid="0" cx="30.5" cy="30.5" r="30.5" _fill="#FF473E" fill-opacity=".1"/><g clip-path="url(#clip0_5501_12448)"><path pid="1" d="M36.967 17H26.12a2.704 2.704 0 00-2.704 2.703v21.681a2.704 2.704 0 002.703 2.705h10.845a2.705 2.705 0 002.705-2.704V19.704A2.704 2.704 0 0036.967 17zm-5.423 25.734c-.935 0-1.693-.605-1.693-1.354 0-.749.758-1.354 1.693-1.354.934 0 1.693.605 1.693 1.354 0 .749-.759 1.354-1.693 1.354zm5.417-4.063H26.126V19.71H36.96V38.67z" _fill="#FF473E"/></g><defs><clipPath id="clip0_5501_12448"><path pid="2" _fill="#fff" transform="translate(18 17)" d="M0 0h27.089v27.089H0z"/></clipPath></defs>'
  }
})
