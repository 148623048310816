/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_glass': {
    width: 41,
    height: 24,
    viewBox: '0 0 41 24',
    data: '<path pid="0" d="M38.396 1.275l-.056-.255a1.188 1.188 0 00-1.16-.93H3.736c-.557 0-1.04.387-1.16.93l-.057.255a98.82 98.82 0 00-2.35 21.4c0 .657.533 1.189 1.19 1.189H39.557c.656 0 1.188-.532 1.188-1.189 0-7.185-.79-14.385-2.349-21.4zm-7.86 20.212l-3.559-4.418 1.055-.422a1.188 1.188 0 10-.883-2.207l-5.944 2.377a1.188 1.188 0 10.883 2.207l2.58-1.032 2.816 3.495H18.65l-3.56-4.418 1.055-.422a1.188 1.188 0 10-.883-2.207l-5.944 2.377a1.188 1.188 0 10.883 2.207l2.58-1.032 2.816 3.495H2.556a96.445 96.445 0 012.137-19.02H19.27v2.378h-3.567c-.656 0-1.188.532-1.188 1.188v4.755c0 .657.532 1.189 1.188 1.189h9.51c.657 0 1.189-.532 1.189-1.189V6.033c0-.656-.532-1.188-1.189-1.188h-3.566V2.467h14.576a96.47 96.47 0 012.138 19.02h-7.824zM24.025 7.222V9.6h-7.132V7.222h7.132z" _fill="#0269AD"/>'
  }
})
