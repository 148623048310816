/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_collision': {
    width: 33,
    height: 34,
    viewBox: '0 0 33 34',
    data: '<path pid="0" d="M32.587 16.1h-5.296l-3.78 5.732h-3.493l-3.556 1.778-3.557-1.778H9.412L5.632 16.1H.336v15.18h4.35a3.78 3.78 0 003.272 1.89 3.781 3.781 0 003.271-1.89h10.465a3.78 3.78 0 003.271 1.89 3.781 3.781 0 003.272-1.89h4.35V16.1zm-4.278 1.89h2.389v3.842h-4.923l2.534-3.843zm-23.695 0l2.534 3.842H2.226v-3.843h2.388zm3.344 13.29a1.892 1.892 0 01-1.89-1.89c0-1.041.848-1.889 1.89-1.889 1.042 0 1.89.848 1.89 1.89 0 1.042-.848 1.89-1.89 1.89zm3.78-1.89a3.784 3.784 0 00-3.78-3.779 3.784 3.784 0 00-3.78 3.78H2.226v-5.67h10.233l3.058 1.53v.36h-2.835v1.89h2.835v1.89h-3.78zm13.227 1.89a1.892 1.892 0 01-1.89-1.89c0-1.041.849-1.889 1.89-1.889 1.043 0 1.89.848 1.89 1.89 0 1.042-.847 1.89-1.89 1.89zm3.78-1.89a3.784 3.784 0 00-3.78-3.779 3.784 3.784 0 00-3.779 3.78h-3.78V27.5h2.835v-1.89h-2.834v-.36l3.057-1.53h10.234v5.67h-1.953z" _fill="#FFE799"/><path pid="1" d="M7.843 12.79l1.092.418a3.341 3.341 0 012.14 2.946l.06 1.167 1.129-.304a3.341 3.341 0 013.463 1.126l.735.909.735-.91a3.34 3.34 0 013.463-1.124l1.128.303.06-1.167a3.341 3.341 0 012.14-2.946l1.092-.418-.637-.98a3.34 3.34 0 010-3.64l.637-.98-1.091-.419a3.34 3.34 0 01-2.14-2.945l-.06-1.167-1.13.303a3.34 3.34 0 01-3.462-1.125l-.735-.91-.735.91a3.34 3.34 0 01-3.463 1.125l-1.129-.304-.06 1.168a3.34 3.34 0 01-2.14 2.945l-1.092.418.638.98a3.341 3.341 0 010 3.641l-.638.98zm2.701-4.723a5.237 5.237 0 002.26-3.111 5.239 5.239 0 003.658-1.188 5.236 5.236 0 003.657 1.188 5.237 5.237 0 002.26 3.11 5.236 5.236 0 000 3.846 5.237 5.237 0 00-2.26 3.111 5.238 5.238 0 00-3.657 1.189 5.237 5.237 0 00-3.657-1.189 5.237 5.237 0 00-2.26-3.11 5.236 5.236 0 000-3.846z" _fill="#FFE799"/><path pid="2" d="M15.517 5.266h1.89v5.669h-1.89v-5.67zM15.517 12.824h1.89v1.89h-1.89v-1.89z" _fill="#FFE799"/>'
  }
})
