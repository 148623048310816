/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_arrow': {
    width: 34,
    height: 30,
    viewBox: '0 0 34 30',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M13.314.858L.586 13.586a2 2 0 000 2.828l12.728 12.728a2 2 0 102.828-2.828L6.828 17H34v-4H6.828l9.314-9.314A2 2 0 1013.314.858z" _fill="#fff"/>'
  }
})
