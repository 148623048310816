/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_subscription': {
    width: 61,
    height: 61,
    viewBox: '0 0 61 61',
    data: '<circle pid="0" cx="30.5" cy="30.5" r="30.5" _fill="#49BF7C" fill-opacity=".1"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M31 47c8.837 0 16-7.163 16-16s-7.163-16-16-16-16 7.163-16 16 7.163 16 16 16zm-5.335-22.941a3.489 3.489 0 012.061-.678h6.548c1.508 0 2.855.968 3.274 2.42l.602 2.42h.393c.503 0 .838.323.838.807v.807c0 .484-.335.806-.838.806v5.648c0 .484-.335.806-.838.806h-1.676c-.503 0-.839-.322-.839-.806v-.807h-8.38v.806c0 .485-.336.807-.839.807h-1.676c-.503 0-.838-.322-.838-.806V30.64c-.503 0-.838-.322-.838-.806v-.807c0-.484.335-.807.838-.807h.392l.604-2.42a3.271 3.271 0 011.212-1.742zm.463 2.145c.168-.726.844-1.21 1.598-1.21h6.548c.754 0 1.43.484 1.598 1.21l.706 2.824H25.422l.706-2.823zm.262 5.244c.671 0 1.258.565 1.258 1.21 0 .646-.587 1.21-1.258 1.21-.67 0-1.257-.564-1.257-1.21 0-.645.587-1.21 1.258-1.21zm9.22 0c.67 0 1.257.565 1.257 1.21 0 .646-.587 1.21-1.258 1.21-.67 0-1.257-.564-1.257-1.21 0-.645.587-1.21 1.258-1.21z" _fill="#49BF7C"/>'
  }
})
