/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_health': {
    width: 52,
    height: 52,
    viewBox: '0 0 52 52',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M21.666 4.332a3.25 3.25 0 00-3.25 3.25h-3.25a3.25 3.25 0 00-3.25 3.25v30.333a3.25 3.25 0 003.25 3.25h13V42.25h-13a1.083 1.083 0 01-1.083-1.084V10.832a1.083 1.083 0 011.083-1.083h3.25a3.25 3.25 0 003.25 3.25h8.667a3.25 3.25 0 003.25-3.25h3.25a1.083 1.083 0 011.083 1.083v16.25h2.167v-16.25a3.25 3.25 0 00-3.25-3.25h-3.25a3.25 3.25 0 00-3.25-3.25h-8.667zm-1.083 3.25a1.083 1.083 0 011.083-1.083h8.667a1.083 1.083 0 011.083 1.083v2.167a1.083 1.083 0 01-1.083 1.083h-8.667a1.083 1.083 0 01-1.083-1.083V7.582zm4.333 13v-3.25h2.167v3.25h3.25v2.167h-3.25v3.25h-2.167v-3.25h-3.25v-2.167h3.25zm4.333 9.75a1.083 1.083 0 011.084-1.083h13a1.083 1.083 0 011.083 1.083v7.355a7.583 7.583 0 01-3.377 6.31L37.434 46.4a1.083 1.083 0 01-1.203 0l-3.605-2.405a7.585 7.585 0 01-3.377-6.308v-7.356zm2.167 1.083v6.272a5.417 5.417 0 002.413 4.507l3.004 2.005 3.004-2.005a5.415 5.415 0 002.412-4.506v-6.273H31.416zm5.1 8.35l4.333-4.334-1.532-1.532-3.568 3.568-1.4-1.4-1.532 1.53 2.166 2.168a1.083 1.083 0 001.532 0z" _fill="#606060"/>'
  }
})
