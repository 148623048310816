/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_bookmark': {
    width: 34,
    height: 34,
    viewBox: '0 0 34 34',
    data: '<path pid="0" d="M28.8 33.707H5.2a1.434 1.434 0 01-1.434-1.435V1.68C3.766.888 4.408.246 5.2.246h17.467l7.567 7.487v24.54c0 .791-.642 1.434-1.434 1.434z" _fill="#DCE2E2"/><path pid="1" d="M19.41 5.294H7.075a.525.525 0 010-1.05h12.333a.525.525 0 110 1.05zM18.04 8.38a.525.525 0 00-.524-.525H7.076a.525.525 0 000 1.05h10.44a.525.525 0 00.526-.525zm8.186 3.612a.525.525 0 00-.524-.525H7.076a.525.525 0 100 1.05h18.627a.525.525 0 00.524-.525zm-1.169 3.612a.525.525 0 00-.524-.525H7.076a.525.525 0 000 1.05h17.458a.524.524 0 00.524-.525zm-7.017 3.612a.525.525 0 00-.524-.524H7.076a.525.525 0 000 1.05h10.44a.524.524 0 00.526-.526zm8.186 3.613a.525.525 0 00-.524-.525H7.076a.525.525 0 000 1.05h18.627a.525.525 0 00.524-.526zm0 3.612a.525.525 0 00-.524-.525H7.076a.525.525 0 000 1.05h18.627a.525.525 0 00.524-.525zm0 3.612a.525.525 0 00-.524-.525H7.076a.525.525 0 100 1.05h18.627a.525.525 0 00.524-.525z" _fill="#96A9B2"/><path pid="2" d="M22.667.246l7.487 7.487h-5.896a1.591 1.591 0 01-1.591-1.592V.246zM30.22 24.697h-4.198c-.276 0-.542-.1-.748-.284L23.456 22.8a1.126 1.126 0 010-1.684l1.818-1.614c.206-.182.472-.283.747-.283h4.199" _fill="#B9C5C6"/><path pid="3" d="M31.252 23.678h-6.515a1.02 1.02 0 01-.747-.346l-2.069-2.237a1.136 1.136 0 010-1.512l2.069-2.237a1.02 1.02 0 01.747-.346h6.515c.622 0 1.126.614 1.126 1.373v3.932c0 .758-.505 1.373-1.126 1.373z" _fill="#FF6E83"/>'
  }
})
