/* eslint-disable */
require('./ic_add-button')
require('./ic_aiplane')
require('./ic_airplane-mode')
require('./ic_all_risk')
require('./ic_app_store')
require('./ic_arrow')
require('./ic_bookmark')
require('./ic_car_insurance')
require('./ic_car')
require('./ic_clock')
require('./ic_close')
require('./ic_collision')
require('./ic_declaration')
require('./ic_estimation')
require('./ic_faq')
require('./ic_flame')
require('./ic_glass')
require('./ic_habitation_insurance')
require('./ic_health')
require('./ic_help')
require('./ic_home')
require('./ic_logo_aab')
require('./ic_logo_oremi')
require('./ic_logo_whatsapp')
require('./ic_logo')
require('./ic_logos_whatsapp')
require('./ic_menu')
require('./ic_moto_insurance')
require('./ic_motorcycle')
require('./ic_open_down')
require('./ic_payment')
require('./ic_phone')
require('./ic_pin')
require('./ic_play_store')
require('./ic_radio')
require('./ic_selection')
require('./ic_signup')
require('./ic_subscription')
require('./ic_thief')
require('./ic_travel_insurance')
require('./ic_user')
require('./ic_whatsapp')
