/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_clock': {
    width: 34,
    height: 34,
    viewBox: '0 0 34 34',
    data: '<path pid="0" d="M16.993 32.158c-8.372 0-15.182-6.811-15.182-15.183S8.62 1.793 16.993 1.793s15.182 6.81 15.182 15.182-6.81 15.183-15.182 15.183z" _fill="#E5E4DF"/><path pid="1" d="M16.993 2.962c7.727 0 14.013 6.286 14.013 14.013s-6.287 14.013-14.013 14.013c-7.727 0-14.014-6.286-14.014-14.013S9.266 2.962 16.993 2.962zm0-2.84C7.684.121.139 7.666.139 16.974c0 9.308 7.545 16.854 16.854 16.854 9.308 0 16.854-7.546 16.854-16.854S26.3.121 16.992.121z" _fill="#FF473E"/><path pid="2" d="M16.992 18.228c-.678 0-1.228-.55-1.228-1.229V6.11a1.229 1.229 0 112.457 0V17c0 .679-.55 1.229-1.229 1.229z" _fill="#3A5D66"/><path pid="3" d="M21.773 8.692a1.228 1.228 0 00-1.678.45l-3.54 6.133a1.757 1.757 0 102.128 1.228l3.54-6.133a1.228 1.228 0 00-.45-1.678z" _fill="#183F4C"/>'
  }
})
