/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_travel_insurance': {
    width: 42,
    height: 42,
    viewBox: '0 0 42 42',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M21 42c11.598 0 21-9.402 21-21S32.598 0 21 0 0 9.402 0 21s9.402 21 21 21zm10.94-15.402a.198.198 0 00-.172-.097h-2.406l-6.122-6.1 8.077-2.149a.66.66 0 00.483-.204.66.66 0 00.204-.483V16.19a.66.66 0 00-.204-.484.66.66 0 00-.483-.204H18.342l-3.866-3.845c-.359-.372-1.103-.784-2.235-1.235-1.13-.451-1.829-.54-2.094-.268-.265.272-.172.97.28 2.094.45 1.124.855 1.872 1.213 2.245l3.867 3.845v12.975a.68.68 0 00.687.687h1.375a.66.66 0 00.483-.204.66.66 0 00.204-.483l2.148-8.077 6.101 6.079v2.449c0 .071.032.129.097.172a.437.437 0 00.247.064h.687c.1 0 .183-.021.247-.064a.198.198 0 00.097-.172l1.117-2.771 2.77-1.117a.198.198 0 00.173-.097.437.437 0 00.064-.247v-.688c0-.1-.021-.182-.064-.247z" _fill="#FFC300"/>'
  }
})
