/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_car_insurance': {
    width: 42,
    height: 42,
    viewBox: '0 0 42 42',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M21 42c11.598 0 21-9.402 21-21S32.598 0 21 0 0 9.402 0 21s9.402 21 21 21zm-7.002-30.11a4.58 4.58 0 012.705-.89h8.594c1.98 0 3.747 1.27 4.297 3.177l.79 3.176h.516c.66 0 1.1.423 1.1 1.059v1.059c0 .635-.44 1.058-1.1 1.058v7.412c0 .636-.44 1.059-1.1 1.059h-2.2c-.66 0-1.1-.424-1.1-1.059v-1.059h-11v1.06c0 .634-.44 1.058-1.1 1.058h-2.2c-.66 0-1.1-.424-1.1-1.059V20.53c-.66 0-1.1-.423-1.1-1.058v-1.06c0-.635.44-1.058 1.1-1.058h.515l.792-3.177a4.294 4.294 0 011.59-2.287zm.608 2.816c.22-.953 1.107-1.588 2.097-1.588h8.594c.99 0 1.877.635 2.097 1.588l.928 3.706H13.678l.928-3.706zm.344 6.882c.88 0 1.65.741 1.65 1.588 0 .848-.77 1.589-1.65 1.589-.88 0-1.65-.742-1.65-1.588 0-.848.77-1.589 1.65-1.589zm12.1 0c.88 0 1.65.741 1.65 1.588 0 .848-.77 1.589-1.65 1.589-.88 0-1.65-.742-1.65-1.588 0-.848.77-1.589 1.65-1.589z" _fill="#0269AD"/>'
  }
})
